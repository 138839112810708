          
     <template>
   <v-card dark>
   <v-img src="@/assets/RABaseBG.jpeg">
       
        <v-card-subtitle>
         <v-select v-model="activeChart" :items="charts" clearable label="Select Chart"></v-select>
        </v-card-subtitle>
         <v-layout row class="justify-center">
         <v-card dark>
         <v-card-title>
            <v-layout row class="justify-center" v-if="selectedLabel && selectedLabelIdentifier">
         {{selectedLabel[selectedLabelIdentifier]}}
            </v-layout>
        </v-card-title>
        <v-card-title>
            <v-layout row class="justify-center" v-if="!selectedLabel && activeChart || !selectedLabelIdentifier && activeChart">
               {{activeChart}} - Total List
            </v-layout>
        </v-card-title>
        <v-card-title>
           <v-layout row class="justify-center" v-if="activeChart && ChartDatalength">
                <v-btn @click="ViewTable()" light>View Data<v-badge color="pop" overlap dark>
                    <v-icon>mdi-database</v-icon>
                 <span slot="badge"> {{ChartDatalength}} </span>
                </v-badge></v-btn>
            </v-layout>
        </v-card-title>
         
        <v-card-text>
          <v-layout row class="justify-center">
          <canvas id="myChart" width="400" height="400"></canvas>
        </v-layout>
        </v-card-text>
        </v-card>
         </v-layout>
        </v-img>
      </v-card>


</template>

<script>
import Chart from 'chart.js';
export default {
    props: ['Labels','ChartData','selectedLabel','selectedLabelIdentifier','ChartDatalength'],
    data(){
        return {
            activeChart: '',
            selectedData: {},
            selectedLabel: {},
            chartview: false,
            charts: ['Accounts by Business Unit','Accounts by Owner'],

        }
    },

    computed:{

    },

    created(){

    },


    watch: {
      activeChart (value) {
          let ChartElmnt = document.getElementById('myChart')
        if (value !== null && value !== undefined) {
          this.GetCorrectChart(value)
        }
        else{
          this.chartview = false
          // ChartElmnt.style.display
        }
        this.$emit('ActivateActiveChart',value)
      }
    },

    methods:{
        ViewTable(){
            this.$emit('Viewtable')
        },
        GetCorrectChart(value){
            console.log('watcher fired')
      if(!value || typeof value === 'undefined'){

      }
      else{
        if(value === 'Accounts by Business Unit'){
        console.log('ActiveChart is ',this.activeChart)
        setTimeout(() => {
            this.PrepareBarChart()
        }, 200);
        }
        if(value === 'Accounts by Owner'){
        console.log('ActiveChart is ',this.activeChart)
        setTimeout(() => {
            this.PrepareDoughnutChart()
        }, 200);
        }
        
      }
    },
    ChartDataSelect (point, event) {
      const item = event[0]
      if(typeof item !== 'undefined'){
        if(this.activeChart === 'Accounts by Business Unit'){
            this.$emit('ChartDataSelect',this.activeChart,item._index)
        }
        if(this.activeChart === 'Accounts by Owner'){
            this.$emit('ChartDataSelect',this.activeChart,item._index)
        }
      }
    },
    ColorLooper(i){
      let mix = 256/this.Labels.length
      let indexcolor = i*mix
      if(indexcolor>256){
        indexcolor = 256/indexcolor
      }
      return indexcolor.toFixed().toString()
    },
    ColorLooper2(i){
      let mix = 256/this.Labels.length
      let indexcolor = i*mix
      if(indexcolor>256){
        indexcolor = 256/indexcolor
      }
      return indexcolor.toFixed().toString()
    },
    PrepareBarChart(charttype,chartlabel){
            // console.log(this.UsersArray)
            console.log(this.Labels)
            console.log(this.ChartData)
      var elmnt = document.getElementById('myChart')
      var ctx = elmnt.getContext('2d');
      let colors = this.Labels.map((user,i) => {
        let mix = 256/this.Labels.length
        let indexcolor = i*mix
        let colorobj = ''
        let r = mix+i
        let g = this.ColorLooper(i-1+2)
        let b = this.ColorLooper(i-1+2)
        colorobj = 'rgba(' + [r,g,b].join(',') + ')'
        console.log(colorobj)
        return colorobj
      })
      console.log(colors)
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: this.Labels,
        datasets: [{
            label: '# of Accounts',
            data: this.ChartData,
            backgroundColor: colors,
            
            
            
        }],
    },
    
    options: {
        events: ['click'],
        responsive: false,
        onClick:this.ChartDataSelect,
        legend: {
            display: true,
            labels: {
                fontColor: 'white'
            }
        },
        title: {
        display: true,
        text: this.activeChart,     
        
      },     
      scales: {
        xAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
            }
        }],
        yAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
            }   
        }]
    }
        
    }
});
// elmnt.style.height = '500px'
// elmnt.style.width = '500px'

    },
    PrepareDoughnutChart(){
            // console.log(this.UsersArray)
            console.log(this.Labels)
            console.log(this.ChartData)
      var elmnt = document.getElementById('myChart')
      var ctx = elmnt.getContext('2d');
      let colors = this.Labels.map((user,i) => {
        let mix = 256/this.Labels.length
        let indexcolor = i*mix
        let colorobj = ''
        let r = mix+i
        let g = this.ColorLooper(i-1+2)
        let b = this.ColorLooper(i-1+2)
        colorobj = 'rgba(' + [r,g,b].join(',') + ')'
        console.log(colorobj)
        return colorobj
      })
      console.log(colors)
var myChart = new Chart(ctx, {
    type: 'doughnut',
    data: {
        labels: this.Labels,
        datasets: [{
            label: '# of Accounts',
            data: this.ChartData,
            backgroundColor: colors,
            
            
            
        }],
    },
    options: {
        events: ['click'],
        responsive: false,
        onClick:this.ChartDataSelect,
        legend: {
            display: true,
            labels: {
                fontColor: 'white'
            }
        },
        title: {
        display: true,
        text: 'Accounts by Owner'
      }
        
    }
});
// elmnt.style.height = '500px'
// elmnt.style.width = '500px'

    },

    PreparePieChart(){
            // console.log(this.UsersArray)
            console.log(this.Labels)
            console.log(this.ChartData)
      var elmnt = document.getElementById('myChart')
      var ctx = elmnt.getContext('2d');
      let colors = this.Labels.map((user,i) => {
        let mix = 256/this.Labels.length
        let indexcolor = i*mix
        let colorobj = ''
        let r = mix+i
        let g = this.ColorLooper(i-1+2)
        let b = this.ColorLooper(i-1+2)
        colorobj = 'rgba(' + [r,g,b].join(',') + ')'
        console.log(colorobj)
        return colorobj
      })
      console.log(colors)
var myChart = new Chart(ctx, {
    type: 'pie',
    data: {
        labels: this.Labels,
        datasets: [{
            label: '# of Accounts',
            data: this.ChartData,
            backgroundColor: colors,
            
            
            
        }],
    },
    options: {
        events: ['click'],
        responsive: false,
        onClick:this.ChartDataSelect,
        legend: {
            display: true,
            labels: {
                fontColor: 'white'
            }
        },
        title: {
        display: true,
        text: 'Accounts by Owner'
      }
        
    }
});
// elmnt.style.height = '500px'
// elmnt.style.width = '500px'

    },
    PrepareLineChart(){
            // console.log(this.UsersArray)
            console.log(this.Labels)
            console.log(this.ChartData)
      var elmnt = document.getElementById('myChart')
      var ctx = elmnt.getContext('2d');
      let colors = this.Labels.map((user,i) => {
        let mix = 256/this.Labels.length
        let indexcolor = i*mix
        let colorobj = ''
        let r = mix+i
        let g = this.ColorLooper(i-1+2)
        let b = this.ColorLooper(i-1+2)
        colorobj = 'rgba(' + [r,g,b].join(',') + ')'
        console.log(colorobj)
        return colorobj
      })
      console.log(colors)
var myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: this.Labels,
        datasets: [{
            label: '# of Accounts',
            data: this.ChartData,
            backgroundColor: colors,
            
            
            
        }],
    },
    options: {
        events: ['click'],
        responsive: false,
        onClick:this.ChartDataSelect,
        legend: {
            display: true,
            labels: {
                fontColor: 'white'
            }
        },
        title: {
        display: true,
        text: 'Accounts by Owner'
      }
        
    }
});
// elmnt.style.height = '500px'
// elmnt.style.width = '500px'

    },
    }
}
</script>

<style>

</style>

    
            
        